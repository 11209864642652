import Cookies from 'js-cookie';
import { FC, ReactNode, useEffect, useState } from 'react';

import { AccountHeader, NotSignedIn } from '@components/Account';
import { BulkErrorBoundary } from '@components/Alerts/ErrorBoundaryFallback';
import { Loading } from '@components/Alerts/Loading';
import { Footer } from '@components/Footer';
import { Header } from '@components/Header';
import { useCustomer } from '@hooks/customer/useCustomer';
import { useIsTabActive } from '@hooks/useIsTabActive';
import { useLoadingState } from '@hooks/useLoadingState';
import { COOKIES } from '@lib/constants';
type Props = {
  children: ReactNode;
};

const AccountLayout: FC<Props> = ({ children }) => {
  const { customer, isLoading } = useCustomer();
  const tabInFocus = useIsTabActive();
  const { isLoaded, stateLoaded } = useLoadingState(1);
  const [loggedIn, setLoggedIn] = useState(false);
  const [customerToken, setCustomerToken] = useState<string | null>(null);

  // Handle logout between tabs
  // Read the customer cookie into state whenever the tab becomes active
  useEffect(() => {
    if (tabInFocus) {
      const cookie = Cookies.get(COOKIES.customer);
      if (cookie && customerToken === null) {
        setCustomerToken(cookie); // Set the cookie in state
      } else if (cookie && cookie !== customerToken) {
        window.location.reload(); // customer has changed
      }
    }
  }, [tabInFocus]);

  // Customer changes, update login status
  useEffect(() => {
    let mounted = true;
    if (mounted && customer !== undefined) {
      setLoggedIn(customer !== null);
    }
    if (mounted && !isLoading && !isLoaded()) {
      stateLoaded();
    }
    return function cleanup() {
      mounted = false;
    };
  }, [customer, stateLoaded, isLoading, isLoaded]);

  if (!isLoaded()) return <Loading />;

  return (
    <>
      <Header />
      {loggedIn || process.env.NEXT_PUBLIC_AUTH_REQUIRED === 'false' ? (
        <>
          <BulkErrorBoundary location="Account Header">
            <AccountHeader />
            {children}
          </BulkErrorBoundary>
        </>
      ) : (
        <NotSignedIn />
      )}
      <Footer />
    </>
  );
};

export { AccountLayout };
